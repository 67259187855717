<template>
  <footer class="footer">
    <div class="svg-wave">
      <img src="../assets/svg/wave-footer.svg" alt="Волны">
    </div>
    <div class="footer__container">
      <div class="footer__wrap">
      <div class="footer__left">
        <div class="footer__logo" @click="routeIndex">
          <img src="../assets/svg/logo-img.svg" alt="Логотип">
          <div class="footer__logo-text">
          <p class="text-TTDrugs-regular-16 color-black">
            КГБУЗ Алтайский краевой клинический перинатальный центр "ДАР"
          </p>
          </div>
        </div>
        <div class="footer__contacts">
          <div class="footer__contacts-wrap-text">
          <p class="text-circe-24 color-green">Контакты:</p>
          </div>
          <div class="footer__contacts-wrap-text">
          <p class="text-TTDrugs-regular-18"> <a href="tel:591130" class="">(3852) 591-130</a></p>
          </div>
          <div class="footer__contacts-wrap-text">
          <p class="text-TTDrugs-regular-18"><a href="tel:+79635365408" class="" >8 (963) 536 54 08</a></p>
          </div>
        </div>
        <div class="footer__address">
          <div class="footer__address-wrap-text">
          <p class="text-circe-24 color-green">Адрес:</p>
          </div>
          <div class="footer__address-wrap-text">
          <p class="text-TTDrugs-regular-18 color-black">Алтайский край, г. Барнаул, ул. Фомина, д. 154</p>
          </div>
          <div class="footer__address-wrap-text">
            <a class="text-TTDrugs-regular-18 color-black"  href="mailto:akkpc@zdravalt.ru">e-mail: akkpc@zdravalt.ru</a>
          </div>
        </div>
        <div class="footer__social">
          <a href="https://ok.ru/perinat" target="_blank">
            <img src="../assets/svg/odnoklassniki.svg" alt="Одноклассники">
          </a>
          <a href="https://t.me/DARBARNAUL" target="_blank">
            <img src="../assets/svg/telegram.svg" alt="Телеграм">
          </a>
          <a href="https://vk.com/dar_altay_russia" target="_blank">
            <img src="../assets/svg/vk-dark.svg" alt="ВКонтакте">
          </a>
        </div>
      </div>
      <div class="footer__right">
        <nav class="footer__nav">
          <ul class="footer__list">
            <li class="footer__item">
              <router-link to="/about/specialists" class="text-TTDrugs-regular-16 text-uppercase">Специалисты</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/documents" class="text-TTDrugs-regular-16 text-uppercase">Документы</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/education" class="text-TTDrugs-regular-16 text-uppercase">Образование</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/paid-services" class="text-TTDrugs-regular-16 text-uppercase">Платные услуги</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/documents/anti-corruption" class="text-TTDrugs-regular-16 text-uppercase">Противодействие коррупции</router-link>
            </li>
            <li class="footer__item">
              <router-link to="/appeal" class="text-TTDrugs-regular-16 text-uppercase">Написать обращение</router-link>
            </li>
          </ul>
        </nav>

      </div>
    </div>
      <div class="footer__bottom">
        <div class="footer__bottom-right">
          <div class="footer__bottom-right-wrap">
            <p class="text-circe-14 color-black">
              *Цены и сроки готовности результатов анализов, указанные на сайте, носят
              информационный характер, являются актуальными на текущее время и могут быть
              изменены на дату оплаты. Ценовое предложение не является публичной офертой.
              Всю информацию необходимо уточнять у администратора.
            </p>
          </div>
        </div>
    <div class="footer__bottom-left">
      <div class="footer__bottom-left-wrap">
      <p class="text-circe-14 color-black">ВОЗМОЖНЫ ПРОТИВОПОКАЗАНИЯ. ТРЕБУЕТСЯ КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА</p>
      </div>
      <div class="footer__bottom-left-text">
      <p class="text-circe-14 color-black">
        Копирование, перепечатка и другое использование материалов сайта возможны
        только с письменного разрешения администрации.
      </p>
      </div>
    </div>

      </div>
    </div>

  </footer>
</template>

<script>
  export default {
    name: "Footer",
    methods: {
      routeIndex() {
        this.$router.push('/')
      }
    }

  };
</script>

