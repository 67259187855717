var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer"},[_vm._m(0),_c('div',{staticClass:"footer__container"},[_c('div',{staticClass:"footer__wrap"},[_c('div',{staticClass:"footer__left"},[_c('div',{staticClass:"footer__logo",on:{"click":_vm.routeIndex}},[_c('img',{attrs:{"src":require("../assets/svg/logo-img.svg"),"alt":"Логотип"}}),_vm._m(1)]),_vm._m(2),_vm._m(3),_vm._m(4)]),_c('div',{staticClass:"footer__right"},[_c('nav',{staticClass:"footer__nav"},[_c('ul',{staticClass:"footer__list"},[_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/about/specialists"}},[_vm._v("Специалисты")])],1),_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/documents"}},[_vm._v("Документы")])],1),_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/education"}},[_vm._v("Образование")])],1),_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/paid-services"}},[_vm._v("Платные услуги")])],1),_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/documents/anti-corruption"}},[_vm._v("Противодействие коррупции")])],1),_c('li',{staticClass:"footer__item"},[_c('router-link',{staticClass:"text-TTDrugs-regular-16 text-uppercase",attrs:{"to":"/appeal"}},[_vm._v("Написать обращение")])],1)])])])]),_vm._m(5)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"svg-wave"},[_c('img',{attrs:{"src":require("../assets/svg/wave-footer.svg"),"alt":"Волны"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__logo-text"},[_c('p',{staticClass:"text-TTDrugs-regular-16 color-black"},[_vm._v(" КГБУЗ Алтайский краевой клинический перинатальный центр \"ДАР\" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__contacts"},[_c('div',{staticClass:"footer__contacts-wrap-text"},[_c('p',{staticClass:"text-circe-24 color-green"},[_vm._v("Контакты:")])]),_c('div',{staticClass:"footer__contacts-wrap-text"},[_c('p',{staticClass:"text-TTDrugs-regular-18"},[_c('a',{attrs:{"href":"tel:591130"}},[_vm._v("(3852) 591-130")])])]),_c('div',{staticClass:"footer__contacts-wrap-text"},[_c('p',{staticClass:"text-TTDrugs-regular-18"},[_c('a',{attrs:{"href":"tel:+79635365408"}},[_vm._v("8 (963) 536 54 08")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__address"},[_c('div',{staticClass:"footer__address-wrap-text"},[_c('p',{staticClass:"text-circe-24 color-green"},[_vm._v("Адрес:")])]),_c('div',{staticClass:"footer__address-wrap-text"},[_c('p',{staticClass:"text-TTDrugs-regular-18 color-black"},[_vm._v("Алтайский край, г. Барнаул, ул. Фомина, д. 154")])]),_c('div',{staticClass:"footer__address-wrap-text"},[_c('a',{staticClass:"text-TTDrugs-regular-18 color-black",attrs:{"href":"mailto:akkpc@zdravalt.ru"}},[_vm._v("e-mail: akkpc@zdravalt.ru")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__social"},[_c('a',{attrs:{"href":"https://ok.ru/perinat","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/odnoklassniki.svg"),"alt":"Одноклассники"}})]),_c('a',{attrs:{"href":"https://t.me/DARBARNAUL","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/telegram.svg"),"alt":"Телеграм"}})]),_c('a',{attrs:{"href":"https://vk.com/dar_altay_russia","target":"_blank"}},[_c('img',{attrs:{"src":require("../assets/svg/vk-dark.svg"),"alt":"ВКонтакте"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footer__bottom"},[_c('div',{staticClass:"footer__bottom-right"},[_c('div',{staticClass:"footer__bottom-right-wrap"},[_c('p',{staticClass:"text-circe-14 color-black"},[_vm._v(" *Цены и сроки готовности результатов анализов, указанные на сайте, носят информационный характер, являются актуальными на текущее время и могут быть изменены на дату оплаты. Ценовое предложение не является публичной офертой. Всю информацию необходимо уточнять у администратора. ")])])]),_c('div',{staticClass:"footer__bottom-left"},[_c('div',{staticClass:"footer__bottom-left-wrap"},[_c('p',{staticClass:"text-circe-14 color-black"},[_vm._v("ВОЗМОЖНЫ ПРОТИВОПОКАЗАНИЯ. ТРЕБУЕТСЯ КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА")])]),_c('div',{staticClass:"footer__bottom-left-text"},[_c('p',{staticClass:"text-circe-14 color-black"},[_vm._v(" Копирование, перепечатка и другое использование материалов сайта возможны только с письменного разрешения администрации. ")])])])])
}]

export { render, staticRenderFns }